import React, { useState, useEffect } from "react";
import "assets/styles/pages/sidebar.scss";
import { Link, navigate } from "gatsby";
import treeicon from "assets/images/admin/tree-icon.png";
import breadcumbarrow from "assets/images/admin/breadcumb-arrow-left.png";
import { isMobile } from "react-device-detect";

import "react-pro-sidebar/dist/css/styles.css";

import "assets/styles/pages/customheader.scss";

import AdminService from "services/admin/admin.service";

import { useForm } from "react-hook-form";

import { toast, ToastContainer } from "react-toastify";

import PlantationListDetails from "services/plantation/plantation.service";

import closesmall from "assets/images/icons/close-small-icon.svg";

import imageCompression from "browser-image-compression";

import { compressOptions } from "assets/data/imageCompress";
import { doAdminLogout } from "../login";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useQueryParam, NumberParam } from "use-query-params";

export default function Editprojectpage() {
  const adminApi = new AdminService();
  const ds = new PlantationListDetails();
  const [adminId] = useQueryParam("id", NumberParam);

  const [projectEditData, setProjectEditData] = useState([]);
  const [loading, setLoading] = useState([false]);
  const [filesList, setFilesList] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [imageError, setImageError] = useState(false);

  const [description, setDescription] = useState("");
  const [statusType, setStatusType] = useState("");
  const [name, setName] = useState("");
  const [filesDataList, setFilesDataList] = useState([]);
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [plantationOrder, setPlantationOrder] = useState("");
  const [trackId, setTrackId] = useState("");
  const [trackDelete, setTrackDelete] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [deletedImage, setDeletedImage] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const saveImg = async () => {
    setLoading(true);
    const plantationResponse = await ds.imagesSignedUrl({
      signedUrlDetails: signedList,
    });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
    await imageUploadCall(plantationResponse.data.signedUrlDetails);
  };

  const imageUploadCall = async (data) => {
    setLoading(true);
    let pathImages = [];
    const resultsData = await data.map((item, index) => {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: filesList[index],
        redirect: "follow",
      };
      setLoading(true);
      return fetch(item.signedUrl, requestOptions).then((result) => {
        var obj = {};
        obj["pictureUrl"] = data[index].objectPath;
        pathImages.push(obj);
      });
    });
    return Promise.all(resultsData)
      .then(() => {
        uploadImageCall(pathImages, adminId);
      })
      .catch((e) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const fileLoad = async (e) => {
    let testFile = e.target.files[0];
    const reader = new FileReader();
    let fileExtension = testFile.type.split("/").pop();
    const compressedFile = await imageCompression(testFile, compressOptions);
    if (
      fileExtension !== "png" &&
      fileExtension !== "jpg" &&
      fileExtension !== "jpeg" &&
      fileExtension !== "gif"
    ) {
      setTypeError(true);
      setFileError(false);
    } else if (compressedFile.size > 2e6) {
      setSizeError(true);
      setFileError(false);
      setTypeError(false);
    } else {
      setLoading(true);
      if (e.target.files) {
        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const file = e.target.files[0];
        const filetype = e.target.files[0].type;
        let fileExtension = file.type.split("/").pop();
        let imgData = {
          fileName: fileName,
          fileExtension: fileExtension,
          fileType: filetype,
        };
        const filesData = [...filesList, compressedFile];
        const signedData = [...signedList, imgData];
        setFilesList(filesData);
        setFilesDataList([...filesDataList, e.target.files[0]]);
        setSignedList(signedData);
        setFileError(false);
        setLoading(false);
        setTypeError(false);
        setSizeError(false);
      }
    }
  };

  const imageRemove = (e) => {
    let myArray;
    let infoArray;
    myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray);
    infoArray = signedList.filter(function (obj) {
      const lastDot = e.currentTarget.id.lastIndexOf(".");
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray);
  };

  const imageRemoveApi = () => {
    const deleteIdData = {
      trackingDetailId: adminId,
      imageLogId: trackId,
    };

    adminApi
      .deleteRecentPlantationImage(deleteIdData)
      .then((res) => {
        toast.success("Imaged Removed successfully");

        if (res.status === 200) {
          setTrackDelete(false);
          setDeletedImage(deleteIdData);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const onSubmit = async () => {
    if (projectEditData?.imageResource?.length === 0 || undefined || null) {
      setFileError(true);
    } else {
      if (
        name !== "" &&
        description !== "" &&
        plantationOrder !== "" &&
        statusType !== ""
      ) {
        if (filesDataList.length === 0) {
          setLoading(true);
          let formData = {
            title: name,
            description: description,
            order: Number(plantationOrder),
            status: statusType === "1" ? "INACTIVE" : "ACTIVE",
          };

          adminApi
            .editRecentPlantation(formData, adminId)
            .then((data) => {
              if (data.status === 200) {
                toast.success("Plantation Details Updated successfully");
                setSignedList([]);
                setTimeout(function () {
                  navigate("/admin/recentplantationstatus");
                  setLoading(false);
                }, 2000);
              } else {
                toast.error("Something went wrong");
                setLoading(false);
              }
            })
            .catch((error) => {
              setSignedList([]);
              setLoading(false);
              if (error.response) {
                if (error.response.status === 401) {
                  toast.error("JWT Token has Expired");
                  navigate("/");
                } else {
                  toast.error(error.response.data.message);
                }
              }
            });
        } else {
          await saveImg();
        }
      }
    }
  };

  const imageUploadApi = () => {
    setLoading(true);
    saveImg();
  };

  const loadProjectData = async (editId) => {
    setLoading(true);
    await adminApi
      .getRecentPlantationById(editId)
      .then((data) => {
        if (data.status === 200) {
          setProjectEditData(data.data);
          setName(data.data.title);
          setDescription(data.data.description);
          setPlantationOrder(data.data.order);
          setStatusType(data.data.status === "ACTIVE" ? 2 : 1);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            doAdminLogout();
            toast.error("Authentication failed");
            navigate("/admin/");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const statusChange = (e) => {
    setStatusType(e.target.value);
  };
  useEffect(() => {
    if (adminId !== null || undefined) {
      loadProjectData(adminId);
    }
  }, [adminId]);

  const deleteOpen = (deleteId) => {
    if (projectEditData?.imageResource?.length === 1) {
      setImageError(true);
    } else {
      setTrackId(deleteId);
      setTrackDelete(true);
    }
  };
  const trackClose = () => {
    setTrackDelete(false);
    setTrackId("");
  };

  useEffect(() => {
    if (filesList.length > 0) {
      setShowUpload(true);
    } else if (filesList.length === 0) {
      setShowUpload(false);
    }
  }, [filesList]);

  const uploadImageCall = (pathImages, uploadId) => {
    const payload = { pictureDetailRequest: pathImages };
    adminApi
      .addRecentPlantationImage(payload, uploadId)
      .then((data) => {
        if (data.status === 200) {
          pathImages.length = 0;
          setSignedList([]);
          toast.success("Plantation Image added successfully");
          window.location.reload();
        } else {
          setLoading(false);
          pathImages.length = 0;
          setSignedList([]);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          pathImages.length = 0;
          setSignedList([]);
          if (error.response.status === 401) {
            toast.error("Authentication Failed");
            navigate("/admin");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };

  useEffect(() => {
    if (deletedImage?.imageLogId) {
      setProjectEditData({
        ...projectEditData,
        imageResource: projectEditData?.imageResource?.filter(
          (item) => deletedImage?.imageLogId !== item?.id
        ),
      });
    }
  }, [deletedImage]);

  useEffect(() => {
    if (filesList?.length >= 1) {
      setImageError(false);
    }
  }, [filesList]);

  return (
    <>
      <div className="admin-breadcumb-item">
        <span>
          <Link className="" to="/admin/dashboardview/">
            {" "}
            <img src={treeicon} alt="treeicon" />
          </Link>
        </span>
        <span>
          <img src={breadcumbarrow} alt="arrowicon" />
        </span>
        <h3> {projectEditData.name} </h3>
      </div>
      <div className="white-iitiative-wrapper">
        <div className="">
          <h3 className="sub-heading-title">EDIT RECENT PLANTATION DETAILS </h3>
        </div>

        <div className="register-plantation">
          <div className="container">
            <div className="row">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="fc-wrapper">
                      <label htmlFor="name" className="form-label">
                        Name <i className="hastric-color">*</i>
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control "
                        id="title"
                        placeholder=""
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      {name === "" ? (
                        <span className="form-error">Name is Required</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="fc-wrapper">
                      <label htmlFor="name" className="form-label">
                        Description <i className="hastric-color">*</i>
                      </label>
                      <textarea
                        type="textarea"
                        name="description"
                        className="form-control "
                        id="description"
                        placeholder=""
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                      />
                      {description === "" ? (
                        <span className="form-error">
                          Description is Required
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="order" className="form-label">
                        Order <i className="hastric-color">*</i>
                      </label>
                      <input
                        type="number"
                        name="order"
                        min="1"
                        value={plantationOrder}
                        onChange={(e) => {
                          setPlantationOrder(e.target.value);
                        }}
                        className="form-control "
                        id="order"
                        placeholder=""
                      />
                      {plantationOrder === "" ? (
                        <span className="form-error">Order is Required</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="status" className="form-label">
                        Status <i className="hastric-color">*</i>
                      </label>
                      <select
                        name="status"
                        id="status"
                        value={statusType}
                        onChange={(e) => {
                          statusChange(e);
                        }}
                        className="form-control"
                      >
                        <option value="2">Active</option>
                        <option value="1"> Inactive </option>
                      </select>
                    </div>
                  </div>
                  <div className="addimage-wrapper">
                    <h3 className="title-item">
                      {" "}
                      ADDED IMAGES
                      <i className="hastric-color">*</i>
                    </h3>

                    <div className="dis-flex add-more-img-posi">
                      <div>
                        {projectEditData?.imageResource &&
                          projectEditData?.imageResource?.map((data) => {
                            return (
                              <>
                                <div className="recent-plantation-section-plantation">
                                  <div
                                    className="img-wrapper img_wrp hand-pointer"
                                    key={data}
                                  >
                                    <SimpleReactLightbox>
                                      <SRLWrapper>
                                        <img
                                          src={data.imageUrl}
                                          className="img-fluid"
                                          alt=""
                                          width="140px"
                                          height="100px"
                                        />
                                      </SRLWrapper>
                                    </SimpleReactLightbox>

                                    <div>
                                      <span className="small-close small-close-images hand-pointer close">
                                        <div
                                          id={data.name}
                                          onClick={() => {
                                            deleteOpen(data?.id);
                                          }}
                                        >
                                          <img
                                            src={closesmall}
                                            className="img-fluid"
                                            alt="Close small"
                                          />{" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>

                      <div>
                        <div className="dis-flex add-more-img-posi">
                          {filesList &&
                            filesList.map((fileData, index) => {
                              return (
                                <div
                                  className="img_wrp-container"
                                  key={index + 1}
                                >
                                  <span className="small-close small-close-images hand-pointer close">
                                    <div
                                      id={fileData.name}
                                      onClick={imageRemove}
                                    >
                                      {" "}
                                      <img
                                        src={closesmall}
                                        className="img-fluid"
                                        alt="Close small"
                                      />{" "}
                                    </div>
                                  </span>
                                  <SimpleReactLightbox>
                                    <SRLWrapper>
                                      <img
                                        src={URL.createObjectURL(fileData)}
                                        className="img-fluid"
                                        alt="Add"
                                        width="150px"
                                        height="150px"
                                      />
                                    </SRLWrapper>
                                  </SimpleReactLightbox>
                                </div>
                              );
                            })}
                          <div className="border-items">
                            <span className="plus-icon-green btn-file">
                              <i className="icon icon-plus-lg plus-symbol-custom-icon"></i>
                              <input
                                type="file"
                                multiple
                                onChange={fileLoad}
                                disabled={filesList.length === 5}
                                accept="image/png, image/gif, image/jpeg"
                              />
                              Add more
                            </span>
                          </div>
                        </div>
                        <div className={isMobile ? `` : `margin-admin-10`}>
                          {sizeError === true ? (
                            <span className="form-error">
                              Please upload a file smaller than 2 MB
                            </span>
                          ) : null}
                          {typeError === true ? (
                            <span className="form-error">
                              File does not support. You must use .png or .jpg
                            </span>
                          ) : null}
                          {fileError === true ? (
                            <span className="form-error">
                              Add at least one Image
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {imageError === true ? (
                      <div className={isMobile ? `` : `margin-admin`}>
                        <span className="form-error">
                          At least one Image Required
                        </span>
                      </div>
                    ) : null}
                  </div>
                  {showUpload && (
                    <button
                      type="button"
                      className="btn btn-approve save-width"
                      onClick={() => imageUploadApi()}
                    >
                      {" "}
                      Upload{" "}
                    </button>
                  )}
                </div>
                <div className="text-right">
                  <Link
                    to="/admin/recentplantationstatus"
                    className="btn btn-cancel mg-right10 margin-btm-10"
                  >
                    Cancel
                  </Link>

                  <button className="btn btn-viewmore save-width">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal show={trackDelete} centered>
        <Modal.Header closeButton onClick={trackClose}>
          <Modal.Title>Delete Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            No
          </Button>
          <Button variant="primary" onClick={() => imageRemoveApi()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
