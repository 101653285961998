import React from "react";

import AdminLayout from "../../adminlayout/index";

import SEO from "components/seo/index";
// import Side from 'layout/sidebaritem';

import Editrecentplantationpage from "components/admin/recentPlantation/editrecentplantationpage";

const Editrecentplantation = ({ location }) => {
  const dataValue =
    location.state && location.state.editData !== undefined
      ? location.state.editData
      : null;
  return (
    <>
      <SEO title="Forests By Heartfulness admin" />
      <AdminLayout>
        <div>
          <Editrecentplantationpage data={dataValue} />
        </div>
      </AdminLayout>
    </>
  );
};

export default Editrecentplantation;
